import styled from "styled-components";

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
  width: 100%;
  height: 60px;
  background-color: transparent;
  box-sizing: border-box;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const MenuToggle = styled.div`
  cursor: pointer;
  margin-left: 1rem;
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fafafa;
  padding: 1rem;
  border: 1px solid #ccc;
`;

const MenuItem = styled.li`
  list-style: none;
  margin: 0.5rem 0;
  cursor: pointer;
`;

const ContainerBadge = styled.div`
  position: relative;
`;

const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarFallback = styled.div<{ bgColor: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: white;
  background-color: ${(props) => props.bgColor};
  border-radius: 50%;
`;

const IconContainer = styled.div`
  position: relative;
  margin-right: 15px;
`;

const NotificationBadge = styled.span`
padding: 0px 4px;
gap: 8px;
border-radius: 4px;
background-color: #2bb2fe;
color: #fff;
position: absolute;
top: -8px;
right: -7px;
`;

export {
  Container,
  Logo,
  Menu,
  MenuToggle,
  MenuItem,
  ContainerBadge,
  AvatarContainer,
  AvatarImage,
  AvatarFallback,
  IconContainer,
  NotificationBadge,
};
