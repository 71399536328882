import styled from "styled-components";

const Container = styled.div`
  display: block;
  overflow: hidden;

  @media (max-width: 900px) {
    margin-left: 0px;
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export { Container, Content };
