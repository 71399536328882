import React, { useState, createContext, useEffect } from "react";
import { api } from "../services/api";
// import authService from '~/services/authService';

export interface Entry {
  date: string;
  entry: string;
  exit: string;
  situation: string;
  shift: string;
}

interface Status {
  id: number;
  description: string;
}

export interface ITable {
  date: string;
  class: string;
  amount: number;
  status: Status;
}

export interface IToggle {
  toggleName: string;
  status: "active" | "inactive";
}

interface DataItem {
  date: string;
  entry: string;
  exit: string;
  situation: string;
  shift: number;
}

interface IFrequencies {
  isLoading: boolean;
  data?: DataItem[];
}

interface IGym {
  id: number;
  label: string;
}

export interface IPageContext {
  collapseMenu: boolean;
  setCollapseMenu: React.Dispatch<React.SetStateAction<boolean>>;
  isLogged: Object | any;
  setIsLogged: React.Dispatch<React.SetStateAction<Object | any>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  user: Object | any;
  setUser: React.Dispatch<React.SetStateAction<Object | any>>;
  frequencies: IFrequencies;
  setFrequencies: React.Dispatch<React.SetStateAction<IFrequencies>>;
  history: Entry[];
  setHistory: React.Dispatch<React.SetStateAction<Entry[]>>;
  tabelaPG: ITable[];
  setTabelaPG: React.Dispatch<React.SetStateAction<ITable[]>>;
  colorFoto: string;
  setColorFoto: React.Dispatch<React.SetStateAction<string>>;
  countCalendar: number;
  setCountCalendar: React.Dispatch<React.SetStateAction<number>>;
  countMail: number;
  setCountMail: React.Dispatch<React.SetStateAction<number>>;
  countNotifications: number;
  setCountNotifications: React.Dispatch<React.SetStateAction<number>>;
  toggles: IToggle[];
  setToggles: React.Dispatch<React.SetStateAction<IToggle[]>>;
  selectedGym: IGym | null;
  setSelectedGym: React.Dispatch<React.SetStateAction<IGym | null>>;
}

const PageContext = createContext<IPageContext>({} as IPageContext);

const PageProvider: React.FC<any> = ({ children }: any) => {
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    isLoading: true,
    data: {},
  });
  const [frequencies, setFrequencies] = useState<IFrequencies>({
    isLoading: true,
    data: [],
  });
  const [history, setHistory] = useState<Entry[]>([]);
  const [tabelaPG, setTabelaPG] = useState<ITable[]>([]);
  const [colorFoto, setColorFoto] = useState<string>("");
  const [countCalendar, setCountCalendar] = useState<number>(0);
  const [countMail, setCountMail] = useState<number>(0);
  const [countNotifications, setCountNotifications] = useState<number>(0);
  const [toggles, setToggles] = useState<IToggle[]>([]);
  const [selectedGym, setSelectedGym] = useState<IGym | null>(null);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);

      const userLocalStorage = localStorage.getItem("user");

      if (!userLocalStorage) {
        setIsLoading(false);
        return;
      }

      const userParsed =
        typeof userLocalStorage === "string" && JSON.parse(userLocalStorage);
      setUser((prevState) => ({
        ...prevState,
        data: userParsed,
      }));
      setIsLogged(true);
      setIsLoading(false);

      const togglesResponse = await api.get(`/toggles/me`);

      setToggles(togglesResponse.data || []);
    };
    if (!isLogged) {
      load();
    }
  }, [isLogged]);

  return (
    <PageContext.Provider
      value={{
        collapseMenu,
        setCollapseMenu,
        isLoading,
        setIsLoading,
        user,
        setUser,
        history,
        setHistory,
        tabelaPG,
        setTabelaPG,
        frequencies,
        setFrequencies,
        isLogged,
        setIsLogged,
        colorFoto,
        setColorFoto,
        countCalendar,
        setCountCalendar,
        countMail,
        setCountMail,
        countNotifications,
        setCountNotifications,
        toggles,
        setToggles,
        selectedGym,
        setSelectedGym,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
export { PageContext };
