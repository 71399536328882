import React from "react";

interface Props {
  value: number;
}

const FormatCurrency: React.FC<Props> = ({ value }) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value);

  return <span>{formattedValue}</span>;
};

export default FormatCurrency;
