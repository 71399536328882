import { useState, useEffect } from "react";
import { usePageContext } from "../../../../hooks";
import Typography from "../../Typography";
import axios from "axios";
import { MatriculaMobile } from "../../Devices/styles";
import { UserProfile } from "../../Header";
import Spacer from "../../Spacer";
import {
  getFirstName,
  getFullName,
  toLowerCase,
  toUpperCaseFirstLetter,
} from "../../../../utils/utils";
import { ContInfP, InfoC, UserCont } from "./styles";

interface InfoP {
  title: string;
  Text: any;
}

const Informacao: React.FC<InfoP> = ({ title, Text }) => {
  return (
    <div style={{ display: "block" }}>
      <Typography type="Caption">{title}</Typography>
      <br />
      <Text />
    </div>
  );
};

interface InfoCCP {
  label: string;
  conteudo: any;
}

const CidCepPais: React.FC<InfoCCP> = ({ label, conteudo }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography type="Caption" color="#595a5c">
        {label}
      </Typography>
      <div
        style={{
          border: "1px solid  rgba(232, 233, 235, 1)",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        <Typography type="Caption" color="#172635" weight="400">
          {conteudo}
        </Typography>
      </div>
    </div>
  );
};

interface PhoneNumber {
  type: string;
  number: string | number;
}

interface Document {
  type: string;
  number: string | number;
}

const Main = () => {
  const {
    user: { data: userData },
  } = usePageContext();

  const [rua, setRua] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [email, setEmail] = useState<string>("Email não cadastrado");
  const [numero, setNumero] = useState<string>(
    "Nenhum número de telefone disponível"
  );
  const [sexo, setSexo] = useState<string>("Sexo não informado");
  const [cpf, setCpf] = useState<string>("CPF não disponível");

  const formatPhoneNumber = (numero: string): string => {
    if (numero.length <= 2) return numero;
    const areaCode = numero.slice(0, 2);
    const number = numero.slice(2);
    return `${areaCode} ${number}`;
  };

  useEffect(() => {
    if (userData) {
      const {
        personal_info: { mail, sex },
        contact_info: { phone_numbers },
        address: { zip_code },
        documents,
      } = userData;

      

      setEmail(mail || "Email não cadastrado");

      const celular = phone_numbers.find(
        (phone: PhoneNumber) => phone.type === "CELULAR"
      )?.number;
      const residencial = phone_numbers.find(
        (phone: PhoneNumber) => phone.type === "RESIDENCIAL"
      )?.number;
      setNumero(
        celular || residencial || "Nenhum número de telefone disponível"
      );

      setSexo(
        sex === "M"
          ? "Masculino"
          : sex === "F"
            ? "Feminino"
            : "Sexo não informado"
      );

      setCep(zip_code || "CEP não disponível");

      const cpf =
        documents.find((doc: Document) => doc.type === "CPF")?.number ||
        "CPF não disponível";
      setCpf(cpf);
    }
  }, [userData]);

  useEffect(() => {
    if (cep) {
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          setCidade(response.data.localidade || "Cidade não encontrada");
          setRua(response.data.logradouro || "");
        })
        .catch((error) => {
          console.error("Erro ao buscar cidade:", error);
          setCidade("Cidade não encontrada");
        });
    }
  }, [cep]);

  return (
    <MatriculaMobile>
      <UserCont>
        <UserProfile />
      </UserCont>
      <Spacer height="20px" />
      <ContInfP>
        <Informacao
          title="Nome de usuário"
          Text={() => (
            <Typography type="Caption" color="#595a5c">
              {toUpperCaseFirstLetter(
                toLowerCase(
                  getFirstName(
                    userData?.personal_info?.name || "Usuário não encontrado"
                  )
                )
              )}
            </Typography>
          )}
        />
        <Informacao
          title="Nome completo"
          Text={() => (
            <Typography type="Caption" color="#595a5c" weight="400">
              {toUpperCaseFirstLetter(
                toLowerCase(
                  getFullName(
                    userData?.personal_info?.name ||
                      "Nome completo não disponível"
                  )
                )
              )}
            </Typography>
          )}
        />
        <Informacao
          title="Email"
          Text={() => (
            <Typography type="Caption" color="#595a5c" weight="400">
              {email}
            </Typography>
          )}
        />
        <Informacao
          title="Sexo"
          Text={() => (
            <Typography type="Caption" color="#595a5c" weight="400">
              {sexo}
            </Typography>
          )}
        />
        <Informacao
          title="CPF"
          Text={() => (
            <Typography type="Caption" color="#595a5c" weight="400">
              {cpf}
            </Typography>
          )}
        />
        <Informacao
          title="Telefone"
          Text={() => (
            <Typography type="Caption" color="#595a5c" weight="400">
              {formatPhoneNumber(numero)}
            </Typography>
          )}
        />
      </ContInfP>
      <Spacer height="20px" />
      <InfoC>
        <Typography type="Caption" color="#595a5c">
          Endereço de Cobrança
        </Typography>
        <br />
        <div
          style={{
            border: "1px solid  rgba(232, 233, 235, 1)",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <Typography type="Caption" weight="400">
            {rua || "Endereço não disponível"}
          </Typography>
        </div>

        <div style={{ display: "flex", gap: "15px", marginTop: "20px" }}>
          <CidCepPais
            label="Cidade"
            conteudo={cidade || "Cidade não encontrada"}
          />
          <CidCepPais label="Cep" conteudo={cep || "CEP não disponível"} />
          <CidCepPais label="País" conteudo="Brasil" />
        </div>
      </InfoC>
    </MatriculaMobile>
  );
};

export { Main };
