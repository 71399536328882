import styled from "styled-components";

export const Container = styled.main`

  form {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 900px) {
    margin-top: 70px;

    form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 10px;
    }
  }
`;
