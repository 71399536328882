import styled from "styled-components";

const All = styled.div`

  @media (max-width: 900px) {
    margin-top: 60px;
  }
`;

const Container = styled.div`
  background-color: #fff;
  width: 80%; 
  max-width: 1000px;
  height: 40%;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgba(232, 233, 235, 1);

  @media (max-width: 900px) {
    width: 85%;
    height: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px; 
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  display: block;
`;

interface InputPorps {
  width: string;
}

const Input = styled.input<InputPorps>`
  width: ${(props) => props.width || "100px"};
  padding: 10px;
  border: 1px solid rgba(232, 233, 235, 1);
  border-radius: 8px;
  font-size: 16px;

  &:focus {
    outline: 0;
    border: 1px solid rgba(21, 86, 129, 1); 
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;


const Total = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: rgba(85, 100, 118, 1);
  margin-top: 15px;
  line-height: 22.4px;

  display: flex;
  align-items: center;

  span {
    color: rgba(21, 86, 129, 1);
    font-weight: 700;
    font-size: 26px;
    line-height: 36.4px;
    margin: 0px 5px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: rgba(11, 62, 91, 1);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: 0.2s;

  &:hover {
    background-color: rgba(54, 149, 226, 1);
  }

  @media (max-width: 600px) {
    width: 100%; 
    padding: 12px 0;
  }
`;

const ErrorMessage = styled.b`
  color: red;
  font-size: 14px;
  display: block;
  margin-bottom: 4px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const MobileResponseCard = styled.div`
  margin: 10px 0px;

  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export {
  All,
  Container,
  FlexContainer,
  Input,
  Label,
  Total,
  Button,
  ErrorMessage,
  InputContainer,
  MobileResponseCard,
};
