/* eslint-disable jsx-a11y/alt-text */
"use client";

import { useEffect, useRef, useState } from "react";
import Button from "../../Button";
import Input from "../../Input";
import SearchableSelect from "../../SearchableSelect";
import { api } from "../../../../services/api";
import { Box, ContainerInputs, Inputs, InputsNeiborhood, LinkA, WidthBtn } from "./styles";
import Spacer from "../../Spacer";
import Typography from "../../Typography";
import { useModal } from "../../../../hooks/useModal";
import { DesktopOnlyComponent, MobileOnlyComponent } from "../../Devices/styles";

const Form = () => {
  const [gender, setGender] = useState<string>("");
  // eslint-disable-next-line
  const [selectedGenderLabel, setSelectedGenderLabel] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const nomeRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const phoneResidRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const streetRef = useRef<HTMLInputElement>(null);
  const neighborhoodRef = useRef<HTMLInputElement>(null);
  const zipCodeRef = useRef<HTMLInputElement>(null);

  const genderOptions = {
    M: { id: 1, label: "Masculino" },
    F: { id: 2, label: "Feminino" },
    O: { id: 3, label: "Outro" },
  };

  const selectedOption =
    genderOptions[gender as keyof typeof genderOptions] || null;

  const { showModal } = useModal();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get("/students/me");
        if (response.status === 200) {
          const data = response.data;

          if (nomeRef.current)
            nomeRef.current.value = data.personal_info.name || "";

          if (emailRef.current)
            emailRef.current.value = data.personal_info.mail || "";

          if (streetRef.current)
            streetRef.current!.value = data.address.street || "";

          if (neighborhoodRef.current)
            neighborhoodRef.current.value = data.address.neighborhood || "";
          
          if (zipCodeRef.current)
            zipCodeRef.current.value = data.address.zip_code || "";

          if (birthDateRef.current) {
            const birthDate = new Date(data.personal_info.birth_date);
            birthDateRef.current.value =
              birthDate.toISOString().split("T")[0] || "";
          }

          const genderApi = data.personal_info.sex || "";
          setGender(genderApi);

          const phoneNumbers = data.contact_info.phone_numbers;
          const celular =
            phoneNumbers.find(
              (phone: { type: string }) => phone.type === "CELULAR"
            )?.number || "";
          const residencial =
            phoneNumbers.find(
              (phone: { type: string }) => phone.type === "RESIDENCIAL"
            )?.number || "";

          if (phoneRef.current) phoneRef.current.value = celular;
          if (phoneResidRef.current) phoneResidRef.current.value = residencial;
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        showModal({
          type: 'error',
          content: <div>Erro ao carregar os dados.</div>});
      }
    };

    fetchUserData();
  }, [showModal]);

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await api.patch("/students/me", {
        name: nomeRef.current?.value || "",
        birth_date: birthDateRef.current?.value || "",
        mail: emailRef.current?.value || "",
        telefone: phoneRef.current?.value || "",
        celular: phoneResidRef.current?.value || "",
        sex: gender || "",
        address: {
          street: streetRef.current?.value || "",
          neighborhood: neighborhoodRef.current?.value || "",
          zip_code: zipCodeRef.current?.value || "",
        },
      });

      if (response.status === 200) {
        showModal({
          type: 'success',
          content: <div>Dados salvo com sucesso</div>});
      } else {
        showModal({
          type: 'error',
          content: <div>Erro ao salvar os dados.</div>});
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
      showModal({
        type: 'error',
        content: <div>Erro ao salvar os dados.</div>});
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Box width="x" height="x" display="flex">
        <ContainerInputs>
          <Inputs>
            <div style={{
              width: "100%",
            }}> 
              <Typography type="Caption">Nome completo*</Typography>
              <Spacer height="8px" />
              <Input type="text" placeholder="Seu nome" ref={nomeRef} />
            </div>
            <Spacer height="16px" />
            <div style={{
              width: "100%",
            }}>
              <Typography type="Caption">Email*</Typography>
              <Spacer height="8px" />
              <Input type="email" placeholder="Seu email" ref={emailRef} />
            </div>
          </Inputs>
          <Inputs>
            <div style={{
              width: "100%",
            }}>
              <Typography type="Caption">Data de nascimento*</Typography>
              <Spacer height="8px" />
              <Input
                type="date"
                placeholder="Data de nascimento"
                ref={birthDateRef}
              />
            </div>
            <Spacer height="16px" />
            <div style={{
              width: "100%",
            }}>
              <Typography type="Caption">Celular</Typography>
              <Spacer height="8px" />
              <Input type="tel" placeholder="celular" ref={phoneRef} />
            </div>
          </Inputs>
          <Inputs>
            <div style={{
              width: "100%",
            }}>
              <Typography type="Caption">Sexo</Typography>
              <Spacer height="8px" />
              <SearchableSelect
                placeholder="Selecionar"
                data={[
                  { id: 1, label: "Masculino" },
                  { id: 2, label: "Feminino" },
                  { id: 3, label: "Outro" },
                ]}
                selectedOption={selectedOption || undefined}
                onSelectionChange={(selectedOption) => {
                  setSelectedGenderLabel(selectedOption.label);
                  const genderValue =
                    selectedOption.label === "Masculino"
                      ? "M"
                      : selectedOption.label === "Feminino"
                        ? "F"
                        : "O";
                  setGender(genderValue);
                }}
              />
            </div>

            <Spacer height="16px" />
            <div style={{
              width: "100%",
            }}>
              <Typography type="Caption">Telefone</Typography>
              <Spacer height="8px" />
              <Input type="tel" placeholder="Residencial" ref={phoneResidRef} />
            </div>
          </Inputs>
        </ContainerInputs>
      </Box>

      <Spacer height="24px" />

      <Box width="y" height="y" display="flex">
        <div 
        style={{
            width: "100%",
          }}>
          <div>
            <Typography type="Caption">Endereço de Cobrança</Typography>
            <Spacer height="8px" />
            <Input type="text" ref={streetRef} />
          </div>
          <Spacer height="16px"/>
          <InputsNeiborhood>
            <div>
              <Typography type="Caption">Cidade</Typography>
              <Spacer height="8px" />
              <Input type="text" ref={neighborhoodRef} />
            </div>
            <Spacer height="16px"/>
            <div>
              <Typography type="Caption">Cep</Typography>
              <Spacer height="8px" />
              <Input type="text" ref={zipCodeRef}/>
              <LinkA href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank" rel="noreferrer">Não sei meu cep</LinkA>
            </div>
        </InputsNeiborhood>
        </div>
      </Box>

      <MobileOnlyComponent>
        <Spacer height="60px"/>
      </MobileOnlyComponent>
      
      <DesktopOnlyComponent>
      <Spacer height="24px"/>
      </DesktopOnlyComponent>
      <WidthBtn>
        <Button
          $variante="Submit"
          type="submit"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Salvando..." : "Salvar"}
        </Button>
      </WidthBtn>
    </form>
  );
};

export { Form };
