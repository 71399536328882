import { useNavigate } from "react-router-dom";
import { Card } from "./styles";
import Typography from "../Typography";

interface IFormas {
  link: string;
  Photo: any;
  title: string;
}

const Formas: React.FC<IFormas> = ({ link, Photo, title }) => {

  const navigate = useNavigate();

  const handleNavegate = () => {
    navigate(`/${link}`)
  }
  
  return (
    <Card onClick={handleNavegate}>
      <Photo />
      <Typography type="Caption" color="#777980">{title}</Typography>
    </Card>
  );
};

export default Formas;
