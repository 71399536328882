import styled from "styled-components";

export const ContainerPix = styled.div`

  button {
    background-color: rgba(21, 86, 129, 1);
    color: #fff;
    width: 169px;
    height: 49px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    gap: 5px;

    &:hover {
      background-color: #1a6aa0;
      transition: 0.2s;
    }
  }

  @media (max-width: 900px) {
    margin-top: 60px;
  }
`;
export const ConteudoPix = styled.div`
  background-color: rgba(255, 255, 255, 1);
  width: 392px;
  height: 380px;
  border: 1px solid rgba(232, 233, 235, 1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-top: 20px;

  @media (max-width: 900px) {
    width: 90%;
   

    font-size: 12px;
  }
`;
