import React, { useEffect, useState } from "react";
import { FaHistory } from "react-icons/fa";
import { FaPix } from "react-icons/fa6";
import Formas from "../../../FormasPagament";
import {
  Container,
  Input,
  Label,
  Total,
  All,
  Button,
  FlexContainer,
  InputContainer,
  MobileResponseCard,
} from "./styles";
import { api } from "../../../../../services/api";
import { NavegaFormas } from "../../../../../pages/Financeiro/styles";
import Home from "../../../../../pages/Home";
import Typography from "../../../Typography";
import FormatCurrency from "../../Format";
import Skeleton from "../../../Skeleton";
import { useModal } from "../../../../../hooks/useModal";
import { toggles } from "../../../../../enums/toggles";
import CheckToggle from "../../../CheckToggle";
import Spacer from "../../../Spacer";

const PaymentForm: React.FC = () => {
  const [formValues, setFormValues] = useState({
    nome: "",
    numeroCard: "",
    data: "",
    cvc: "",
  });

  const { showModal } = useModal();

  const [loading, setLoading] = useState(false);
  const [mensalidade, setMensalidade] = useState<number | null>(null);

  // Funções de formatação e validação
  const formatCardNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    const formattedValue = numericValue.match(/.{1,4}/g)?.join(" ") || "";
    return formattedValue.substring(0, 19); // Limita a 16 dígitos com espaços
  };

  const formatCardExpiry = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    const formattedValue = numericValue.match(/.{1,2}/g)?.join("/") || "";
    return formattedValue.substring(0, 5); // Limita a 5 caracteres (MM/YY)
  };

  const limitCvcLength = (value: string) => {
    const numericValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos
    return numericValue.substring(0, 4); // Limita a 3 ou 4 dígitos
  };

  const validateForm = () => {
    if (
      !formValues.nome ||
      !formValues.numeroCard ||
      !formValues.data ||
      !formValues.cvc
    ) {
      showModal({
        type: "warning",
        content: <div>Por favor, preencha todos os campos.</div>,
      });
      return false;
    }
    if (formValues.numeroCard.replace(/\s+/g, "").length !== 16) {
      showModal({
        type: "warning",
        content: <div>O número do cartão deve ter 16 dígitos.</div>,
      });
      return false;
    }
    if (formValues.cvc.length !== 3) {
      showModal({
        type: "warning",
        content: <div>O CVV deve ter 3 dígitos.</div>,
      });
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]:
        name === "numeroCard"
          ? formatCardNumber(value)
          : name === "data"
            ? formatCardExpiry(value)
            : name === "cvc"
              ? limitCvcLength(value)
              : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const paymentData = {
      card: {
        number: formValues.numeroCard.replace(/\s+/g, ""),
        holder: formValues.nome,
        cvv: formValues.cvc,
        expirationDate: formValues.data,
        paymentMethod: "CREDIT_CARD",
      },
    };

    setLoading(true);

    try {
      const payment = await api.post(
        "/students/me/monthly-payments",
        paymentData
      );

      if (payment.data.message === "Payment success") {
        showModal({
          type: "success",
          content: <div>Pagamento com sucesso!</div>,
        }); // Mostra o modal de sucesso
        setFormValues({
          nome: "",
          numeroCard: "",
          data: "",
          cvc: "",
        });
      } else {
        showModal({
          type: "error",
          content: <div>Pagamento deu errado.</div>,
        }); // Mensagem de erro
      }
    } catch (error: any) {
      showModal({
        type: "error",
        content: <div>Erro ao processar o pagamento. Tente novamente.</div>,
      }); // Mensagem de erro
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get("/students/me");
        const paymentInfo =
          response?.data?.payment_info?.current_payment?.value;
        setMensalidade(paymentInfo);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    };

    getData();
  }, []);

  return (
    <All>
      <Home />
      <Typography type="Subtitle">Forma de pagamento</Typography>
      <Spacer height="24px" />
      <NavegaFormas>
        <CheckToggle toggleName={toggles.PAYMENT_PIX}>
          <Formas
            title="Pix"
            Photo={() => <FaPix size={24} style={{ color: "gray" }} />}
            link="pix"
          />
        </CheckToggle>
        <Formas
          title="Histórico"
          Photo={() => <FaHistory size={24} style={{ color: "gray" }} />}
          link="financeiro"
        />
      </NavegaFormas>

      <MobileResponseCard>
        <Container>
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <Label htmlFor="nome">Nome no cartão</Label>
              <Input
                width="90%"
                placeholder="Enoque Belmiro dos Santos"
                name="nome"
                value={formValues.nome}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="numeroCard">Número do cartão</Label>
              <Input
                width="90%"
                placeholder="xxxx-xxxx-xxxx-xxxx"
                name="numeroCard"
                value={formValues.numeroCard}
                onChange={handleInputChange}
              />
            </InputContainer>

            <FlexContainer>
              <InputContainer>
                <Label htmlFor="data">Validade</Label>
                <Input
                  width="80px"
                  placeholder="MM/YY"
                  name="data"
                  value={formValues.data}
                  onChange={handleInputChange}
                />
              </InputContainer>

              <InputContainer>
                <Label htmlFor="cvc">CVV</Label>
                <Input
                  width="60px"
                  placeholder="658"
                  name="cvc"
                  value={formValues.cvc}
                  onChange={handleInputChange}
                />
              </InputContainer>
            </FlexContainer>
            <Total>
              Total:{" "}
              <span>
                {mensalidade !== null ? (
                  <FormatCurrency value={mensalidade} />
                ) : (
                  <Skeleton height="30px" width="50px" />
                )}
              </span>{" "}
              /mês
            </Total>

            <Button type="submit" disabled={loading}>
              {loading ? "Processando..." : "Pagar"}
            </Button>
          </form>
        </Container>
      </MobileResponseCard>
    </All>
  );
};

const Cartao: React.FC = () => {
  return <PaymentForm />;
};

export default Cartao;
