import React from "react";
import "moment/locale/pt-br";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Container } from "./styles";
import Typography from "../../components/_ui/Typography";
import Home from "../Home/index";
import { Calendario } from "../../components/_ui/Historico/Main";
import Spacer from "../../components/_ui/Spacer";

const CalendarComponent: React.FC = () => {
  return (
    <Container>
      <Home />
      <Typography type="Subtitle">Histórico</Typography>
      <Spacer height="24px"/> 
      <Calendario />
    </Container>
  );
};

export default CalendarComponent;
