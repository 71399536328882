import styled from "styled-components";

interface BoxProps {
  width?: string;
  height?: string;
  display?: string;
}

export const Box = styled.section<BoxProps>`
  width: calc(100% - 50px);
  max-width: ${(props) => (props.width === "x" ? "970px" : "500px")};
  height: ${(props) => (props.height === "x" ? "180px" : "200px")};
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(232, 233, 235, 1);
  border-radius: 10px;
  display: flex;
  gap: 15px;
  padding: 20px;

  @media (max-width: 1060px) {
    flex-direction: column;
    width: ${(props) => (props.width === "x" ? "500px" : "500px")};
    height: ${(props) => (props.height === "x" ? "300px" : "200px")};
  }

   @media (max-width: 900px) {
    flex-direction: column;
    width: ${(props) => (props.width === "x" ? "310px" : "310px")};
    height: ${(props) => (props.height === "x" ? "580px" : "290px")};
  }
`;

export const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px; 
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 1060px) {
     grid-template-columns: 3fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr; 
  }
`;

export const Inputs = styled.div`

@media (max-width: 1060px) {
     display: flex;
     justify-content: space-between;
  }
@media (max-width: 900px) {
     display: flex;
     flex-direction: column;
  }
`;

export const WidthBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 385px;
  margin-bottom: 10px;

  @media (max-width: 1060px) {
    width: 550px;
  }

  @media (max-width: 900px) {
    position: fixed;
    bottom: -10px;
    width: 102%;
  }
`;

export const InputsNeiborhood = styled.div`
display: flex;
justify-content: space-between;

@media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LinkA = styled.a`
  color: #2980b9;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;

  &:visited {
    color: #9b59b6;
  }

  &:hover {
    color: #155681;
    text-decoration: underline;
  }
`;
