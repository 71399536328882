import styled from "styled-components";

const ContCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* gap: 5px; */

  @media (max-width: 900px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  width: 250px;
  height: 135px;
  padding: 15px;
  border-bottom: 4px solid rgba(54, 149, 226, 1);


  @media (max-width: 900px) {
    width: 317px;
    height: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
`;

const Progresso = styled.div`
  display: flex;
  border-top: 1px solid lightgray;
  height: 40px;
  padding-top: 10px;
  align-items: center;
  gap: 10px;

  div {
    display: flex;
    align-items: center;
  }

  .paga {
    width: 50px;
    height: 20px;
    background: rgba(14, 177, 127, 0.1);
    color: green;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    font-weight: bold;
  }

  .naopaga {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 20px;
    background: rgba(219, 18, 18, 0.1);
    color: red;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    font-weight: bold;
  }

  .porcent {
    width: 50px;
    height: 20px;
    background: rgba(14, 177, 127, 0.1);
    color: green;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    font-weight: bold;

    img {
      width: 15px;
      margin-right: 5px;
    }
  }

  a {
    color: #777980;
  }
`;

export { Card, ContCard, Progresso };