import { ReactNode } from 'react';
import { usePageContext } from '../../../hooks';

interface CheckToggleProps {
    toggleName: string;
    children: ReactNode;
}

const CheckToggle = ({ toggleName, children }: CheckToggleProps) => {
    const { toggles } = usePageContext()

    if (toggles.find(toggle => toggle.toggleName === toggleName)?.status === 'active') return <>{children}</>;

    return null;
};

export default CheckToggle;
