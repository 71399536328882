import React, { useState } from "react";
import {
  Container,
  ContainerItem,
  IconWrapper,
  MenuContainer,
  RedirectItem,
  Hamburger,
  Overlay,
} from "./styles";
import { RiApps2Fill } from "react-icons/ri";
import { FaFileArrowDown } from "react-icons/fa6";
import { IoFolder } from "react-icons/io5";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { FaBars, FaTimes } from "react-icons/fa";
import sagaz_color from "../../../assets/sagaz_color.png";
import Spacer from "../Spacer";
import Typography from "../Typography";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { usePageContext } from "../../../hooks";
import { CgLogOut } from "react-icons/cg";

interface IMenuItem {
  $isActive?: boolean;
  Icon: React.FC<{ isHovered: boolean; isActive: boolean }>;
  title: string;
  text: string;
  onClick: () => void;
}

const MenuItem: React.FC<IMenuItem> = ({
  $isActive = false,
  Icon,
  text,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ContainerItem
      $isActive={$isActive}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon isHovered={isHovered} isActive={$isActive} />
      <Spacer width="10px" />
      <RedirectItem $isActive={$isActive}>
        <Typography type="Menu" color={$isActive ? "#155681" : "#6A6C71"}>
          {text}
        </Typography>
      </RedirectItem>
    </ContainerItem>
  );
};

const Menu: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string>("Dashboard");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (item: string, path: string) => {
    setActiveItem(item);
    setIsMenuOpen(false);
    navigate(path);
  };

  const { setIsLogged, setUser } = usePageContext();

  return (
    <Container>
      <Hamburger>
        <div style={{ width: "200px" }}>
          <img
            alt="Logo"
            src={sagaz_color}
            width={"50%"}
            onClick={() => handleMenuItemClick("Dashboard", "/dashboard")}
          />
        </div>

        <IconWrapper
          onClick={toggleMenu}
          className={isMenuOpen ? "open" : "closed"}
        >
          {!isMenuOpen ? <FaBars size={30} /> : <FaTimes size={30} />}
        </IconWrapper>
      </Hamburger>
      {isMenuOpen && <Overlay $isOpen={isMenuOpen} onClick={toggleMenu} />}
      <MenuContainer $isOpen={isMenuOpen}>
        <div
          style={{
            width: "100%",
            height: "8%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px 0px",
            cursor: "pointer",
          }}
          onClick={() => handleMenuItemClick("Dashboard", "/dashboard")}
        >
          <img alt="Logo" src={sagaz_color} width={"50%"} />
        </div>

        <MenuItem
          $isActive={activeItem === "Dashboard"}
          Icon={({ isHovered, isActive }) => (
            <RiApps2Fill
              color={isActive ? "#155681" : isHovered ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Dashboard"
          text="Dashboard"
          onClick={() => handleMenuItemClick("Dashboard", "/dashboard")}
        />

        <MenuItem
          $isActive={activeItem === "Historico"}
          Icon={({ isHovered, isActive }) => (
            <FaFileArrowDown
              color={isActive ? "#155681" : isHovered ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Historico"
          text="Histórico"
          onClick={() => handleMenuItemClick("Historico", "/historico")}
        />

        <MenuItem
          $isActive={activeItem === "Matricula"}
          Icon={({ isHovered, isActive }) => (
            <IoFolder
              color={isActive ? "#155681" : isHovered ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Matricula"
          text="Matrícula"
          onClick={() => handleMenuItemClick("Matricula", "/matricula")}
        />

        <MenuItem
          $isActive={activeItem === "Financeiro"}
          Icon={({ isHovered, isActive }) => (
            <FaMoneyCheckDollar
              color={isActive ? "#155681" : isHovered ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Financeiro"
          text="Financeiro"
          onClick={() => handleMenuItemClick("Financeiro", "/financeiro")}
        />

        <MenuItem
          $isActive={activeItem === "Perfil"}
          Icon={({ isHovered, isActive }) => (
            <FaUser
              color={isActive ? "#155681" : isHovered ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Perfil"
          text="Perfil"
          onClick={() => handleMenuItemClick("Perfil", "/perfil")}
        />

        <Button
          $variante="tertiary"
          onClick={() => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            setUser({});
            setIsLogged(false);
            window.location.reload();
          }}
        >
          <CgLogOut size={30} /> Sair
        </Button>
      </MenuContainer>
    </Container>
  );
};

export default Menu;
