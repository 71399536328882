import React from "react";
import Formas from "../../components/_ui/FormasPagament";
import { ContainerF, NavegaFormas } from "./styles";
import { FaPix, FaCreditCard } from "react-icons/fa6";
import Spacer from "../../components/_ui/Spacer";
import Typography from "../../components/_ui/Typography";
import Home from "../Home/index";
import { TabelaFinanceiro } from "../../components/_ui/Financeiro/Tabela";
import CheckToggle from "../../components/_ui/CheckToggle";
import { toggles } from "../../enums/toggles";

export const Financeiro: React.FC = () => {
  return (
    <ContainerF>
      <Home />
      <Typography type="Subtitle">Histórico de Pagamento</Typography>
      <Spacer height="24px" />
      <NavegaFormas>
        <CheckToggle toggleName={toggles.PAYMENT_CREDIT_CARD}>
          <Formas
            title="Cartão de Crédito"
            Photo={() => <FaCreditCard size={24} style={{ color: "gray" }} />}
            link="cartao"
          />
        </CheckToggle>
        <CheckToggle toggleName={toggles.PAYMENT_PIX}>
          <Formas
            title="Pix"
            Photo={() => <FaPix size={24} style={{ color: "gray" }} />}
            link="pix"
          />
        </CheckToggle>
      </NavegaFormas>{" "}
      <Spacer height="24px" />
      <TabelaFinanceiro />
    </ContainerF>
  );
};
