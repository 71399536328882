import { ContainerMa } from "./styles";
import Typography from "../../components/_ui/Typography";
import Home from "../Home";
import { Main } from "../../components/_ui/Matricula/Main";
import Spacer from "../../components/_ui/Spacer";

const Matricula: React.FC = () => {
  return (
    <ContainerMa>
      <Home />
      <Typography type="Subtitle">Dados de usuário</Typography>
      <Spacer height="24px"/> 
      <Main />
    </ContainerMa>
  );
}

export { Matricula }