import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2.3%;
  box-sizing: border-box;
  flex-direction: column;
`;

export { Container };
