import styled from "styled-components";

const Container = styled.div`
  margin: 0;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const StyledInput = styled.input`
  padding: 18px;
  margin: 0px;
  border: none;
  border-radius: 8px;
  width: auto;
  background: none;

  color: #172635;
  &:focus {
    outline: none;
  }
`;

const Label = styled.p`
  margin-top: -10px;
  margin-left: 5px;
  z-index: 1;
  background: #fff;
  width: 150px;

  &.active {
    display: none;
  }
`;

export { StyledInput, Container, Label };
