import styled from "styled-components";

// Container principal usando grid
const Container = styled.div`
  display: grid;
  grid-template-columns: 264px auto; /* Sidebar e conteúdo em colunas para telas grandes */
  grid-template-rows: 70px auto; /* Header na primeira linha, conteúdo na segunda */
  grid-template-areas:
    "AS MH" /* Sidebar (AS) e Header (MH) */
    "AS CT"; /* Sidebar (AS) e Conteúdo (CT) */

  width: 100%;
  height: 100vh; /* Layout ocupa toda a altura da tela */
  background-color: rgba(
    249,
    249,
    252,
    1
  ); /* Define uma cor de fundo para o Container */

  @media (max-width: 900px) {
    grid-template-columns: 100%; /* Única coluna em telas menores */
    grid-template-rows: 0px auto auto; /* Header na primeira linha, Sidebar na segunda e conteúdo na terceira */
    grid-template-areas:
      "MH" /* Header */
      "AS" /* Sidebar */
      "CT"; /* Conteúdo */
  }
`;

const Sidebar = styled.div`
  grid-area: AS; /* Área nomeada para a sidebar */
  background: none; /* Cor de fundo da sidebar */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  height: 100vh;
  position: fixed; /* Sidebar fixa na tela */
  top: 0; /* Fixada no topo */
  left: 0; /* Fixada à esquerda */
  width: 264px; /* Largura fixa da sidebar */

  @media (max-width: 900px) {
    width: 100%; /* Ocupa toda a largura da tela em telas menores */
    height: auto; /* Ajuste de altura quando necessário */
    position: relative; /* Deixe a sidebar não fixa em dispositivos móveis */
  }
`;

// Cabeçalho (Header)
const Headerr = styled.div`
  grid-area: MH;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  @media (max-width: 800px) {
    display: none;
  }
`;

// Conteúdo principal (Content)
const Section = styled.div`
  grid-area: CT;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(249, 249, 252, 1);
`;

export { Container, Sidebar, Headerr, Section };
