import styled from "styled-components";

const UserCont = styled.div`
  background-color: #fff;
  width: 90%;
  border-radius: 8px;
  padding: 15px;
  border: 2px solid rgba(232, 233, 235, 1);
  font-weight: 500;
`;

const ContInfP = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 90%;
  height: 90%;
  padding: 15px;
  padding-top: 1.8rem;
  border-radius: 8px;
  border: 2px solid rgba(232, 233, 235, 1);

  div {
    padding: 20px;
  }

  @media (min-width: 900px) {
    flex-wrap: wrap;
    width: 90%;
    height: 180px;
  }
`;


const InfoC = styled.div`
  background-color: #fff;
  margin-bottom: 100px;
  border-radius: 8px;
  width: 487px;
  height: 150px;
  top: 653px;
  left: 24px;
  gap: 0px;
  border: 2px solid rgba(232, 233, 235, 1);
  padding: 20px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Conteudo = styled.div`
  display: flex;
  align-items: center;
  width: 140px;
  height: 35px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(232, 233, 235, 1);
`;

export {
    ContInfP,
    Conteudo,
    InfoC,
    UserCont,
}