import styled from "styled-components";

export const WeeklyCalendarContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 450px;
  height: 135px;
  padding: 15px;
  border-bottom: 4px solid rgba(54, 149, 226, 1);

  @media (max-width: 1310px) {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    display: flex;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: rgba(89, 90, 92, 1);
  border: none;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  transition: 0.3s;

  &:hover {
    color: #fff;
    background: #155681;
  }
`;

export const MonthYear = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const Week = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
`;

interface DayProps {
  isActive: boolean;
}

export const Day = styled.div<DayProps>`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? "#155681" : "#fff")};
  color: ${(props) => (props.isActive ? "white" : "#333")};
`;

export const DayNumber = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const DayName = styled.span<DayProps>`
  font-size: 1rem;
  color: ${(props) => (props.isActive ? "white" : "#666")};
`;
