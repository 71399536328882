import React, { useState } from "react";
import {
  Container,
  Pagination,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../Table/styles";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import Typography from "../Typography";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

interface Column {
  header: string;
  accessor: string;
  render?: (value: any, row?: any) => JSX.Element | string;
}

interface TableProps {
  data: any[];
  columns: Column[];
  entriesPerPageOptions?: number[];
  titulo: string;
}

const PaginationComponent: React.FC<{
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}> = ({ totalPages, currentPage, onPageChange }) => {
  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === currentPage || i === currentPage - 1 || i === currentPage + 1) {
        pages.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            style={{
              backgroundColor: i === currentPage ? "#007bff" : "#e8e5e5",
              color: i === currentPage ? "#fff" : "#007bff",
              borderRadius: "8px",
              border: "none",
              margin: "0 4px",
              padding: "6px",
              width: "32px",
              height: "32px",
              cursor: "pointer",
              fontWeight: i === currentPage ? "bold" : "normal",
            }}
          >
            {i}
          </button>
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pages.push(
          <span
            key={i}
            style={{
              backgroundColor: "#e8e5e5",
              color: "#007bff",
              borderRadius: "8px",
              border: "none",
              margin: "0 2px",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            ...
          </span>
        );
      }
    }
    return pages;
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={{
          cursor: currentPage === 1 ? "not-allowed" : "pointer",
          backgroundColor: "#e8e5e5",
          color: "#007bff",
          border: "none",
          borderRadius: "8px",
          padding: "5px",
          width: "32px",
          height: "32px",
        }}
      >
        <FaCaretLeft size={18} />
      </button>
      {renderPages()}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{
          marginLeft: "5px",
          cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          backgroundColor: "#e8e5e5",
          color: "#007bff",
          border: "none",
          borderRadius: "8px",
          padding: "5px",
          width: "32px",
          height: "32px",
        }}
      >
        <FaCaretRight size={18} />
      </button>
    </div>
  );
};

const TableComponent: React.FC<TableProps> = ({
  data,
  columns,
  entriesPerPageOptions = [5, 10, 20],
  titulo,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [entriesPerPage, setEntriesPerPage] = useState<number>(entriesPerPageOptions[0]);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const uniqueDates = Array.from(
    new Set(data.map(item => new Date(item.date).toDateString()))
  );

  const filteredData = data.filter(row => {
    if (!selectedDate) return true; 
    return new Date(row.date).toDateString() === selectedDate; 
  });

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  const displayedEntries = filteredData.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleEntriesPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(event.target.value);
    setCurrentPage(1);
  };

  return (
    <Container>
      <div style={{ padding: "10px 2%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography type="Heading">{titulo}</Typography>
        <div>
          <Select onChange={handleEntriesPerPageChange} style={{ marginLeft: "20px" }}>
            {entriesPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option} entradas por página
              </option>
            ))}
          </Select>
          <Select onChange={handleDateChange} style={{ marginLeft: "20px" }}>
            <option value="">Todas as datas</option>
            {uniqueDates.map((date) => (
              <option key={date} value={date}>
                {format(new Date(date), "dd 'de' MMMM 'de' yyyy", {
                      locale: ptBR,
                    })}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <Table>
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th key={index}>{column.header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {displayedEntries.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <Td key={colIndex}>
                  {column.render
                    ? column.render(row[column.accessor], row)
                    : row[column.accessor]}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Pagination>
        <Typography type="Overline">
          Página {currentPage} de {totalPages}
        </Typography>
        <PaginationComponent
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </Pagination>
    </Container>
  );
};

export default TableComponent;
