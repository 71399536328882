import React from "react";
import { Container } from "./styles";
import Typography from "../../components/_ui/Typography";
import Home from "../Home/index";
import { Form } from "../../components/_ui/Prefil/Form";
import Spacer from "../../components/_ui/Spacer";

const Perfil: React.FC = () => {
  return (
    <Container>
      <Home />
      <Typography type="Subtitle">Editar</Typography>
      <Spacer height="24px" />
      <Form />
    </Container>
  );
};

export { Perfil };
