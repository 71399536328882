import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

class HttpClient {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: `https://api.sagaz.aviait.com.br`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-platform": "WEB",
        "x-version": "1.0.0",
      },
    });
    this.initInterceptors();
  }

  private initInterceptors() {
    this.api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");

        config.headers["x-transaction-id"] = `${Math.random().toString(36).substring(2)}${Math.random()
          .toString(36)
          .substring(2)}`.substring(0, 32);
        config.headers["x-session-id"] = `${Math.random().toString(36).substring(2)}${Math.random()
          .toString(36)
          .substring(2)}`.substring(0, 32);

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return {
          ...error,
          status: error?.response?.status,
          data: error?.response?.data,
        };
      }
    );

    this.api.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          window.location.reload();
        }
        return {
          ...error,
          status: error?.response?.status,
          data: error?.response?.data,
        };
      }
    );
  }
}

export const { api } = new HttpClient();
