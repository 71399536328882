import React, { useState, useEffect } from "react";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi";
import { MonthYear, Button, WeeklyCalendarContainer, Header, Week, Day, DayNumber, DayName } from "./styles";
import { toUpperCaseFirstLetter } from "../../../utils/utils";

const daysOfWeek = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"];

const WeeklyCalendar: React.FC = () => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000); 
    return () => clearInterval(interval);
  }, []);

  const startOfWeek = (date: Date): Date => {
    const start = new Date(date);
    const day = start.getDay();
    const diff = start.getDate() - day + (day === 0 ? -6 : 1); 
    start.setDate(diff);
    return start;
  };

  const getWeekDates = (date: Date): Date[] => {
    const start = startOfWeek(date);
    return Array.from(
      { length: 7 },
      (_, i) =>
        new Date(start.getFullYear(), start.getMonth(), start.getDate() + i)
    );
  };

  const handlePrevWeek = (): void => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
  };

  const handleNextWeek = (): void => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
  };

  const weekDates = getWeekDates(currentDate);
  const today = new Date().toDateString();

  return (
    <WeeklyCalendarContainer>
      <Header>
        <MonthYear>
          {toUpperCaseFirstLetter(currentDate.toLocaleString("default", { month: "long" }))}{" "}
          {currentDate.getFullYear()}
        </MonthYear>
        <span>
          <Button onClick={handlePrevWeek}> <HiChevronLeft size={25}/> </Button>
          <Button onClick={handleNextWeek}> <HiChevronRight size={25}/> </Button>
        </span>
      </Header>
      <Week>
        {weekDates.map((date, index) => (
          <Day key={index} isActive={today === date.toDateString()}>
            <DayNumber>{date.getDate()}</DayNumber>
            <DayName isActive={today === date.toDateString()}>
              {daysOfWeek[index]}
            </DayName>
          </Day>
          
        ))}
      </Week>
    </WeeklyCalendarContainer>
  );
};

export default WeeklyCalendar;
