import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePageContext } from "../../hooks";
import { Container, Sidebar, Headerr, Section } from "./styles";
import Menu from "../../components/_ui/Menu";
import { Header } from "../../components/_ui/Header";
import GridScaper from "../../components/_ui/GridScaper";

const RequiredAuth: React.FC<any> = ({ children }): any => {
  const { isLogged, isLoading } = usePageContext();
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      if (!isLogged) {
        navigate('/login');
      }
    }
  }, [isLogged, isLoading, navigate]);

  if (isLoading) {
    return <h1>Carregando...</h1>
  }

  return (
    <Container>
      {/* Sidebar */}
      <Sidebar>
        <Menu />
      </Sidebar>

      {/* Header */}
      <Headerr>
        <Header />
      </Headerr>

      {/* Conteúdo principal */}
      <Section>
        <GridScaper>
          {children} {/* Conteúdo da página protegida */}
        </GridScaper>
      </Section>
    </Container>
  );
};

export default RequiredAuth;
