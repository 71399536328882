import React from "react";
import { Container } from "./style";
import Spacer from "../../components/_ui/Spacer";
import Home from "../Home/index";
import TabelaDashboard from "../../components/_ui/Dashboard/Tabela";
import { HeaderDashboard } from "../../components/_ui/Dashboard/Header";

const Dashboard: React.FC = () => {
  return (
    <div>
      <Home />
      <Container>
        <HeaderDashboard />
        <Spacer height="24px" />
        <TabelaDashboard />
      </Container>
    </div>
  );
};

export { Dashboard };
