import React from 'react';
import { Text } from './styles';

type TextType = "Title" | "Subtitle" | "Heading"  | "Body" | "Caption" | "Overline" | "Menu";

interface TypographyProps {
  type: TextType;
  color?: string;
  weight?: string;
  children: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({ type, color, children, weight }) => {
  return (
    <Text type={type} color={color} weight={weight} >
      {children}
    </Text>
  );
};

export default Typography;
