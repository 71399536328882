import React, { ReactNode } from "react";
import { Backdrop, CloseButton, ModalContainer } from "./styles";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";
import Logo from "../../../assets/sagaz_color.png";

interface ModalProps {
  type: 'success' | 'error' | 'warning'; 
  content: ReactNode;
  onClose: () => void;
}

const iconMap = {
  success: <FaRegCheckCircle color="#4CAF50" size={55} />,
  error: <IoCloseCircleOutline color="#f44336" size={55} />,
  warning: <IoWarningOutline color="#ff9800" size={55} />,
};

const buttonTextMap = {
  success: 'Fechar',
  error: 'Tentar novamente',
  warning: 'Entendi',
};

const Modal: React.FC<ModalProps> = ({ type, content, onClose }) => {
  return (
    <Backdrop onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()} type={type}>
        <div className="logo">
          <img src={Logo} alt="logo sagaz" />
        </div>
        <div className="icon">{iconMap[type] || iconMap["error"]}</div>
        <div className="modal-content">{content}</div>
        <CloseButton onClick={onClose} type={type}>
          {buttonTextMap[type]}
        </CloseButton>
      </ModalContainer>
    </Backdrop>
  );
};

export default Modal;
