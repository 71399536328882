import styled, { keyframes } from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9); 
  }
  to {
    opacity: 1;
    transform: scale(1); 
  }
`;

const ModalContainer = styled.div<{ type: 'success' | 'error' | 'warning' }>`
  background-color: #fff;
  border-radius: 8px;
  z-index: 1001;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 100%;
  height: 240px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  animation: ${fadeIn} 0.3s ease-out; 
  margin: 0 2.3%;
  padding: 10px 0px 0px 0px;

  .logo {
    display: flex;
    justify-content: center;
    img {
      width: 100px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
  }

  .modal-content {
    display: flex;
    justify-content: center;
  }
`;

interface CloseButtonProps {
  type: 'success' | 'error' | 'warning';
}

const CloseButton = styled.button<CloseButtonProps>`
  width: 100%;
  height: 22%;
  background-color:  ${({ type }) => (type === 'success' ? '#4CAF50' : type === 'error' ? '#f44336' : '#ff9800')};
  border: none;
  text-align: center;
  cursor: pointer;
  border-radius: 0px 0px 8px 8px;
  padding: 10px;
  color: #fff;

  &:hover {
    background-color: ${({ type }) =>
      type === 'success' ? '#66bb6a' : type === 'error' ? '#e57373' : '#ffb74d'};
  }
`;

export {
    Backdrop,
    ModalContainer,
    CloseButton
}