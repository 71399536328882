import styled from "styled-components";

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
`;

export const CalendarContainer = styled.div`
  width: 90%;
  height: 100%;

  .rbc-calendar {
    height: 500px;
    width: 100% !important;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding-top: 10px;
  }

  .rbc-month-view {
    height: 500px;
    width: 100%;
  }

  .rbc-toolbar {
    padding-left: 10px;
    padding-right: 10px;
    color: #000;
  }

  .rbc-toolbar button {
    background-color: #fff;
    transition: 0.5s;

    &:hover {
      background-color: #e8e8e8;
    }

    &.rbc-active {
      background-color: #e8e8e8;

      &:hover {
        background-color: #e8e8e8;
      }
    }
  }

  .rbc-month-view {
    border-color: #ddd;
  }

  .rbc-event {
    background-color: rgba(227, 248, 239, 1) !important;
    color: rgba(14, 177, 127, 1) !important;
    border-left: 3px solid rgba(14, 177, 127, 1) !important;
    border-radius: 2px;
    width: 100%;
    height: 150%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      width: 100%;
      font-size: 0.8rem;

      @media (min-width: 768px) {
        display: inline-block;
      }
    }
  }
`;

export const ContainerEventCalendar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;

  img {
    width: 30px;
    height: 25px;
    margin-right: 4px;
    border-radius: 50%;
    border: 1px solid #fff;

    @media (max-width: 900px) {
      display: none;
    }
  }
`;

export const Nomes = styled.div`
  display: flex;
  align-items: center;
  color: #00a152;
  width: 100px;
`;
