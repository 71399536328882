import styled from "styled-components";

export const ContainerF = styled.div``;

export const NavegaFormas = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 900px) {
    gap: 20px;
    margin-top: 10px;
    margin-left: 10px;
  }
`;

export const ContainerResponsive = styled.div`
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
