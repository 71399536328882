import { useEffect, useState } from "react";
import { usePageContext } from "../../../../hooks";
import { formatInTimeZone } from "date-fns-tz";
import { ptBR } from "date-fns/locale";
import Typography from "../../Typography";
import TableComponent from "../../Table";
import Skeleton from "../../Skeleton";
import { getFirstName, toLowerCase, toUpperCaseFirstLetter } from "../../../../utils/utils";
import FormatCurrency from "../Format";

interface IStatus {
  id: number;
  description: string;
}

const TabelaFinanceiro = () => {
  const { tabelaPG, user: { data: userData } } = usePageContext();
  
  const [isMobile, setIsMobile] = useState(false);

  const timeZone = "America/Sao_Paulo";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    handleResize(); 
    window.addEventListener("resize", handleResize); 

    return () => {
      window.removeEventListener("resize", handleResize); 
    };
  }, []);

  const columns = [
    {
      header: "Data",
      accessor: "date",
      render: (value: any) => (
        <Typography type="Caption">
          {formatInTimeZone(new Date(value), timeZone, "dd 'de' MMMM 'de' yyyy", {
            locale: ptBR,
          })}
        </Typography>
      ),
    },
    !isMobile
      ? {
          header: "Usuário",
          accessor: "user",
          render: (value: any) => (
            <div>
              <Typography type="Caption">
              {toUpperCaseFirstLetter(
                        toLowerCase(getFirstName(userData?.personal_info?.name))
                      )}
              </Typography>
              <br />
              <Typography type="Overline">
                {userData?.personal_info?.mail}
              </Typography>
            </div>
          ),
        }
      : undefined,
    {
      header: "Total",
      accessor: "amount",
      render: (value: any, row: any) => (
        <Typography
          type="Caption"
          color={row.status.description === "PENDING" ? "rgba(228, 101, 101, 1)" : "rgba(26, 152, 130, 1)"}
        >
          <FormatCurrency value={value} />
        </Typography>
      ),
    },
    {
      header: "Status",
      accessor: "status",
      render: (status: IStatus) => (
        <div
          style={{
            display: "inline-block",
            backgroundColor:
              status.description === "PENDING"
                ? "rgba(228, 101, 101, 1)"
                : "rgba(233, 250, 247, 1)", 
            padding: "4px 8px", 
            borderRadius: "4px",
          }}
        >
          <Typography
            type="Caption"
            color={status.description === "PENDING" ? "#fff" : "rgba(26, 152, 130, 1)"}
          >
            {status.description === "PENDING" ? "Pendente" : "Pago"}
          </Typography>
        </div>
      ),
    },
  ].filter((column) => column !== undefined); 

  return (
    <div>
      {tabelaPG.length === 0 ? (
        <Skeleton height="400px" width="100%" />
      ) : (
        <TableComponent
          data={tabelaPG}
          columns={columns as any} 
          titulo="Histórico de pagamentos"
        />
      )}
    </div>
  );
};

export { TabelaFinanceiro };
