import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  border: 2px solid rgba(232, 233, 235, 1);
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const Table = styled.table`
  width: 100%;
  height: 20px;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
  background-color: rgba(249, 249, 252, 1);
`;

export const Th = styled.th`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  background-color: rgba(249, 249, 252, 1);
  text-align: left;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  background-color: #fff;
`;

export const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const PageButton = styled.button<{ isActive: boolean }>`
  padding: 5px 10px;
  margin: 0 5px;
  background-color: ${(props) => (props.isActive ? "#1E7DBC" : "#155682")};
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #1a6aa0;
    transition: 0.5s;
  }
`;

export const Select = styled.select`
  appearance: none;
  padding: 10px;
  /* font-size: 16px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23999' d='M2 0L0 2h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 8px 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;

  :hover {
    border-color: #888;
  }

  :focus {
    border-color: #555;
    outline: none;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;
