import styled from "styled-components";

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15%;
  height: 100%;
  flex-direction: column;
  background-color: #fff;
  box-sizing: border-box;

  @media (max-width: 900px) {
    width: 100%;
    height: 55px;
    padding: 10px;
    position: fixed;
    top: 0px;
    background-color: #fff;
    border-bottom: 1px solid rgba(232, 233, 235, 1);
  }
`;

const ContainerItem = styled.a<{ $isActive?: boolean }>`
  display: flex;
  width: 100%;
  height: 5%;
  flex-direction: row;
  background-color: none;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  padding: 1% 10%;
  background-color: ${({ $isActive }) => ($isActive ? "#D9EDFD" : "#fff")};
  border-left: ${({ $isActive }) => ($isActive ? "#155681" : "#fff")} solid 4px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.1s;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: 0;
    color: #155681;
  }
`;

const RedirectItem = styled.div<{ $isActive?: boolean }>`
  color: ${({ $isActive }) => ($isActive ? "#155681" : "#6A6C71")};
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  outline: 0;
`;


const Hamburger = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 900px) {
    display: none; /* Esconde o botão de hambúrguer em telas grandes */
  }
`;

const HamburgerButton = styled.button`
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 200;

  @media (min-width: 900px) {
    display: none;
  }
`;

const MenuContainer = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ $isOpen }) => ($isOpen ? "0" : "-264px")};
  height: 100vh;
  width: 264px;
  background-color: white;
  transition: left 0.3s ease-in-out;
  z-index: 200;

  @media (min-width: 900px) {
    position: relative;
    left: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba(23, 38, 53, 1);
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;

  &.open {
    transform: rotate(180deg);
  }

  &.closed {
    opacity: 10;
  }
`;

const Overlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  z-index: 150;
`;


export {
  Container,
  ContainerItem,
  RedirectItem,
  HamburgerButton,
  MenuContainer,
  Hamburger,
  IconWrapper,
  Overlay,
};
