import React, { useState, useEffect } from "react";
import { Container, InputField, OptionItem, OptionsList } from "./styles";
import { sleep } from "../../../utils";

interface ItemData {
  id: number;
  label: string;
}

interface SearchableSelectProps {
  data: ItemData[];
  onSelectionChange: (item: ItemData) => void;
  debounceDelay?: number;
  placeholder?: string;
  selectedOption?: ItemData | null;  // Adiciona a prop selectedOption
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  data,
  onSelectionChange,
  debounceDelay = 300,
  placeholder,
  selectedOption,  // Recebe o valor de selectedOption
}) => {
  const [inputValue, setInputValue] = useState(selectedOption?.label || "");  // Usa o valor inicial de selectedOption
  const [filteredData, setFilteredData] = useState<ItemData[]>(data);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    // Atualiza o inputValue sempre que selectedOption mudar
    if (selectedOption) {
      setInputValue(selectedOption?.label || "");
    } else {
      setInputValue("");
    }
  }, [selectedOption]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const filtered = data.filter((item) =>
        item.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredData(filtered);
    }, debounceDelay);

    return () => clearTimeout(handler);
  }, [inputValue, debounceDelay, data]);

  return (
    <Container>
      <InputField
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={async () => {
          await sleep(300);
          setIsFocused(false);
        }}
        placeholder={placeholder || "Search..."}
      />
      {isFocused && filteredData.length > 0 && (  // Verifica se há dados filtrados
        <OptionsList>
          {filteredData.map((item) => (
            <OptionItem
              key={item.id}
              onClick={() => {
                setInputValue(item.label);
                onSelectionChange(item);
                setIsFocused(false); // Fecha a lista de opções ao selecionar
              }}
            >
              {item.label}
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </Container>
  );
};

export default SearchableSelect;
