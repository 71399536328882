import React from 'react';
import styled from 'styled-components';

// Styled component for the skeleton loading animation
const SkeletonWrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;

const SkeletonShimmer = styled.div`
  position: absolute;
  top: 0;
  left: -150px;
  width: 150px;
  height: 100%;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%);
  animation: loading 1.5s infinite;
  
  @keyframes loading {
    0% {
      left: -150px;
    }
    100% {
      left: 100%;
    }
  }
`;

// Skeleton component
const Skeleton = ({ width, height }: { width: string, height: string }) => {
    return (
        <SkeletonWrapper style={{ width, height }}>
            <SkeletonShimmer />
        </SkeletonWrapper>
    );
};

export default Skeleton;
