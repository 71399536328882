//import { RootState } from '@/redux/store';
import { api } from "../../services/api";
import { useEffect } from "react";
import { Container } from "./styles";
import { usePageContext } from "../../hooks";
import {
  getFirstName,
  toLowerCase,
  toUpperCaseFirstLetter,
} from "../../utils/utils";
import Typography from "../../components/_ui/Typography";
import Spacer from "../../components/_ui/Spacer";
import { DesktopOnlyComponent } from "../../components/_ui/Devices/styles";
import Skeleton from "../../components/_ui/Skeleton";

export default function Home() {
  const {
    user: { data: userData, isLoading },
    setFrequencies,
    setUser,
    setColorFoto,
    setTabelaPG,
  } = usePageContext();

  useEffect(() => {
    const initLoading = async () => {
      try {
        setUser((prevState: any) => ({ ...prevState, isLoading: true }));
        setFrequencies({ isLoading: true, data: [] });

        const [studentsResponse, frequenciesResponse, monthlyPaymentsRsponse] =
          await Promise.all([
            await api.get("/students/me"),
            await api.get("/students/me/frequencies"),
            await api.get("/students/me/monthly-Payments"),
          ]);

        setFrequencies({
          isLoading: false,
          data: frequenciesResponse.data.frequencies,
        });
        setUser({
          isLoading: false,
          data: studentsResponse.data,
        });
        setTabelaPG(monthlyPaymentsRsponse.data.monthlyPayments);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    initLoading();
  }, [setUser, setFrequencies, setColorFoto, setTabelaPG]);

  return (
    <Container>
      <DesktopOnlyComponent>
        <Typography type="Title" color="#000" weight="500">
          Seja bem-vindo,{" "}
          {isLoading ? (
            <Skeleton width="80px" height="30px" />
          ) : (
            <Typography type="Title" weight="500">
              {toUpperCaseFirstLetter(
                toLowerCase(getFirstName(userData?.personal_info?.name || ""))
              )}
            </Typography>
          )}
        </Typography>
        <Spacer height="10px" />
        <Typography type="Caption" color="#777980" weight="400">
          Agende idas a academia, perfil, pagamentos e etc..
        </Typography>
        <Spacer height="24px" />
      </DesktopOnlyComponent>
    </Container>
  );
}
